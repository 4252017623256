import { classification, env, site_model } from "utility/config";
import {
  closedIcon,
  hospitalIcon,
  helpDeskIcon,
  userRoleIcon,
  workOrderIcon,
  technicianIcon,
  requesterIcon,
  callCenterIcon,
  feedback,
  assetSupplierIcon,
  hospitalBiomedDeptHeadIcon,
  clusterEquipmentDeptIcon,
  clusterContractDepartmentMemberIcon,
  clusterContractDepartmentManagerIcon,
  clusterEquipmentCommitteeMemberIcon,
  clusterEquipmentCommitteeChairmanIcon,
  headOfMohMaintenanceIcon,
  headOfMohEngIcon,
  hospitalMaintenanceEngIcon,
  headOfDeptIcon,
  hospitalBiomedDeptHeadIconActive,
  clusterEquipmentDeptIconActive,
  clusterContractDepartmentMemberIconActive,
  clusterContractDepartmentManagerIconActive,
  clusterEquipmentCommitteeMemberIconActive,
  clusterEquipmentCommitteeChairmanIconActive,
  headOfMohMaintenanceIconActive,
  hospitalMaintenanceEngIconActive,
  headOfDeptIconActive,
  headOfMohEngIconActive,
  warehouseManagerIconActive,
  pspIcon,
  hmcSpecialIcon,
  hospitalHeadIcon,
  siteManagerIcon,
} from "assets/icons/svgIcons";
import { classificationTabs, PAGE } from "utility/helper/constants";
import { userViewMedical, userViewMedicalNonMedical, userViewNonMedical } from "utility/helper/permissions";

const { PROJECT } = env;

export const getIcon = (type) => {
  switch (type) {
    case "requestor":
      return requesterIcon;
    case "staff":
      return requesterIcon;
    case "site_manager_call_center":
      return siteManagerIcon;
    case "hmc_technician":
      return technicianIcon;
    case "hmc_supervisor":
      return userRoleIcon;
    case "engineer":
      return hospitalIcon;
    case "closed":
      return closedIcon;
    case "helpdesk":
      return helpDeskIcon;
    case "call_center":
      return callCenterIcon;
    case "feedback":
      return feedback;

    case "asset_supplier":
      return requesterIcon;
    case "hospital_warehouse_manager":
      return userRoleIcon;
    case "hospital_equipment_engineer":
      return technicianIcon;
    case "clinical_division_head":
      return hospitalIcon;
    case "hospital_director":
      return hospitalIcon;
    case "cluster_head":
      return hospitalIcon;

    case "cluster_equipment_department":
      return clusterEquipmentDeptIcon;
    case "cluster_contract_department_member":
      return clusterContractDepartmentMemberIcon;
    case "cluster_contract_department_manager":
      return clusterContractDepartmentManagerIcon;
    case "cluster_equipment_engineer":
      return clusterEquipmentCommitteeMemberIcon;
    case "cluster_equipment_committee_chairman":
      return clusterEquipmentCommitteeChairmanIcon;
    case "hospital_equipment_engineer":
      return headOfMohEngIcon;
    case "head_of_department":
    case "head_of_department_non_medical":
      return headOfDeptIcon;
    case "3psp":
      return pspIcon;
    case "hmc_special":
      return hmcSpecialIcon;
    case "hospital_head":
      return hospitalHeadIcon;
    default:
      return workOrderIcon;
  }
};

export const getScrappingIconActive = (type) => {
  switch (type) {
    case "engineer":
      return hospitalBiomedDeptHeadIconActive;
    case "cluster_equipment_department":
      return clusterEquipmentDeptIconActive;
    case "cluster_contract_department_member":
      return clusterContractDepartmentMemberIconActive;
    case "cluster_contract_department_manager":
      return clusterContractDepartmentManagerIconActive;
    case "cluster_equipment_engineer":
      return clusterEquipmentCommitteeMemberIconActive;
    case "cluster_equipment_committee_chairman":
      return clusterEquipmentCommitteeChairmanIconActive;
    case "hospital_equipment_engineer":
      return headOfMohEngIconActive;
    case "hmc_supervisor":
      return hospitalMaintenanceEngIconActive;
    case "head_of_department":
      return headOfDeptIconActive;
    case "hospital_warehouse_manager":
      return warehouseManagerIconActive;
    case "3psp":
      return pspIcon;
    case "hmc_special":
      return hmcSpecialIcon;
    case "hospital_head":
      return hospitalHeadIcon;
    default:
      return workOrderIcon;
  }
};



export const getFeedbackIcon = (type) => {
  switch (type) {
    case "super_admin":
      return userRoleIcon;
    case "unknown":
      return userRoleIcon
    case "requestor":
      return requesterIcon;
    case "staff":
      return requesterIcon;
    case "hmc_technician":
      return technicianIcon;
    case "hmc_supervisor":
      return userRoleIcon;
    case "engineer":
      return hospitalIcon;
    case "closed":
      return closedIcon;
    case "helpdesk":
      return helpDeskIcon;
    case "call_center":
      return callCenterIcon;
    case "feedback":
      return feedback;
    default:
      return workOrderIcon;
  }

}

export const getField = (field) => {
  switch (field) {
    case "nonmedical":
      return "Non-Medical";
    case "medical":
      return "Medical";
    case "generic":
      return "Generic";
    default:
      return "N/A";
  }
};

export const getAssetCategory = (category) => {
  switch (category) {
    case "N/A":
      return "N/A";
    case "EXTEND_WARRANTY":
      return "Extended Warranty";
    case "HMC_CONTRACT":
      return "HMC Contract";
    case "FRAMEWORK_AGREEMENT_CONTRACT":
      return "Framework Agreement Contract";
    default:
      return "N/A";
  }
}

// GETTING USER CLASSIFICATION FROM DIRECT PERMISSIONS
export const getUserClassification = (directPermissions) => {
  switch (true) {
    case directPermissions.includes(userViewMedical):
      return classificationTabs.MEDICAL.tabId; // MEDICAL
    case directPermissions.includes(userViewNonMedical):
      return classificationTabs.NONMEDICAL.tabId; // NONMEDICAL
    case directPermissions.includes(userViewMedicalNonMedical):
      return classificationTabs.MEDICALNONMEDICAL.tabId; // MEDICAL_NONMEDICAL
    default:
      return "";
  }
};
export const getWorkClassifications = (workClassification) => {
  switch (workClassification) {
    case "MECHANICAL":
      return "Mechanical";
    case "ELECTRONIC_COM":
      return "Electronic Com";
    case "ELECTRONIC":
      return "Electronic";
    case "MASONRY":
      return "Masonry";
    case "PLUMBING":
      return "Plumbing";
    case "CARPENTRY":
      return "Carpentry";
    case "ELECTRICAL":
      return "Electrical";
    case "PAINTING":
      return "Painiting";
    case "MEDICAL_GAS":
      return "Medical Gas";
    case "OTHER":
      return "Other";
    default:
      return "";
  }
};

export const getUserRole = (role) => {
  switch (role) {
    case "super_admin":
      return "Super Admin";
    case "site_admin":
      return "Site Admin";
    case "staff":
      return "Staff";
    case "engineer":
      return "MoH Engineer";
    case "site_manager_call_center":
      return "Site Manager / Call Center";  
    case "hmc_admin":
      return "HMC Admin";
    case "hmc_technician":
      return env.PROJECT === "ASCENDSERVICES" ? "Field Engineer (FE)" : "HMC Technician";
    case "hmc_supervisor":
      return env.PROJECT === "ASCENDSERVICES" ? "Senior FE" : "HMC Supervisor";
    case "helpdesk":
      return "Helpdesk";
    case "organization_admin":
      return "Organization Admin";

    case "asset_supplier":
      return "Asset Supplier";
    case "hospital_warehouse_manager":
      return "Hospital Warehouse Manager";
    case "hospital_equipment_engineer":
      return "Hospital Equipment Engineer";
    case "clinical_division_head":
      return "Clinical Division Head";
    case "hospital_director":
      return "Hospital Director";
    case "cluster_equipment_engineer":
      return "Cluster Equipment Engineer";
    case "cluster_head":
      return "Cluster Head";

    case "cluster_equipment_department":
      return "Cluster Equipment Department";
    case "cluster_contract_department_member":
      return "Cluster Contract Department Member";
    case "cluster_contract_department_manager":
      return "Cluster Contract Department Manager";
    case "cluster_equipment_committee_chairman":
      return "Cluster Equipment Committee Chairman";
    case "hospital_maintenance_contractor":
      return "Hospital Maintenance Contractor";
    case "hospital_equipment_engineer":
      return "Head Of BioMedical Moh Engineer";
    case "head_of_department":
      return "Head of Department";
    case "head_of_department_non_medical":
      return "Head of Department Non Medical";
    case "inventory_control":
      return "Inventory Control";
    case "call_center":
      return "Call Center";
    case "requestor":
      return "Service Requestor";
    case "guest":
      return "Guest";
    case "3psp":
      return "3 PSP";
    case "hmc_special":
      return "OEM";
    case "hospital_head":
      return "Hospital Head";
    case "sheet_upload":
      return "Sheet Upload";
    default:
      return "N/A";
  }
};

export const getUserRoleforAsset = (role) => {
  switch (role) {
    case "super_admin":
      return "Hospital Director";
    case "site_admin":
      return "Cluster Head";
    case "engineer":
      return "Clinical Department";
    case "staff":
      return "Asset Supplier";
    case "hmc_admin":
      return "HMC Admin";
    case "hmc_technician":
      return env.PROJECT === "ASCENDSERVICES" ? "Field Engineer (FE)" : "BioMed Manager";
    case "hmc_supervisor":
      return env.PROJECT === "ASCENDSERVICES" ? "Senior FE" : "Warehouse Manager";
    case "helpdesk":
      return "Hospital Director";
    case "organization_admin":
      return "Organization Admin";

    case "asset_supplier":
      return "Asset Supplier";
    case "hospital_warehouse_manager":
      return "Hospital Warehouse Manager";
    case "hospital_equipment_engineer":
      return "Hospital Equipment Engineer";
    case "clinical_division_head":
      return "Clinical Division Head";
    case "hospital_director":
      return "Hospital Director";
    case "cluster_equipment_engineer":
      return "Cluster Equipment Engineer";
    case "cluster_head":
      return "Cluster Head";
    case "call_center":
      return "Call Center";
    case "requestor":
      return "Service Requestor";
    case "guest":
      return "Guest";
    case "3psp":
      return "3 PSP";
    case "hmc_special":
      return "OEM";
    case "hospital_head":
      return "Hospital Head";
    default:
      return "N/A";

  }
};

export const getScRemarksColor = (role) => {
  const scColor = {
    super_admin: "brown_dark",
    hospital_equipment_engineer: "brown_dark",
    cluster_equipment_department: "yellow",
    cluster_contract_department_member: "purple",
    cluster_contract_department_manager: "blue",
    cluster_equipment_engineer: "brown",
    cluster_equipment_committee_chairman: "green",
    hospital_biomedical_engineer: "brown_dark",

    engineer: "brown_dark",
    hospital_equipment_engineer: "green",
    hmc_supervisor: "blue",
    head_of_department: "purple",
    inventory_control: "yellow",
    hospital_warehouse_manager: "brown",

  };
  return scColor[role];
}

export const getAcRemarksColor = (role) => {
  const acColor = {
    super_admin: "brown_dark",
    asset_supplier: "brown_dark",
    hospital_warehouse_manager: "yellow",
    engineer: "purple",
    head_of_department: "purple",
    hospital_equipment_engineer: "blue",
    clinical_division_head: "brown",
    hospital_director: "green",
    cluster_equipment_engineer: "brown_dark",
    cluster_head: "yellow",
  };
  return acColor[role];
};

export const getACStatusColor = (status) => {
  const acColor = {
    ACCEPTED: "green",
    SCRAPPING_INITIATED: "yellow",
    SCRAPPED: "red",
  }
  return acColor[status]
}
export const getUserType = ({ step,steps, woClassification, siteModel, currentRole, classificationType }) => {
  let userType = {
    1: {
      label: getUserRole("hmc_supervisor"),
      type: "hmc_supervisor",
      icon: getIcon("hmc_supervisor"),
    }, // assigning
    2: {
      label: getUserRole(currentRole),
      type: currentRole,
      icon: getIcon(currentRole),
    }, // acceptance
    3: {
      label: getUserRole(currentRole),
      type: currentRole,
      icon: getIcon(currentRole),
    }, // acceptance
    4: {
      label: getUserRole(currentRole),
      type: currentRole,
      icon: getIcon(currentRole),
    }, // approval
    5: {
      label: getUserRole(currentRole),
      type: currentRole,
      icon: getIcon(currentRole),
    }, // acceptance
    6: {
      label: getUserRole(currentRole),
      type: currentRole,
      icon: getIcon(currentRole),
    },
    7: { label: "Work Order Closed", type: "closed", icon: getIcon("closed") }, // close
    8: { label: "Work Order Closed", type: "closed", icon: getIcon("closed") }, // close

  };

  if (classificationType == "hmcSpecial") {
    userType = {
      1: {
        label: getUserRole("hmc_supervisor"),
        type: "hmc_supervisor",
        icon: getIcon("hmc_supervisor"),
      }, // assigning
      2: {
        label: getUserRole("hmc_special"),
        type: currentRole,
        icon: getIcon(currentRole),
      }, // acceptance
      3: {
        label: getUserRole(currentRole),
        type: currentRole,
        icon: getIcon(currentRole),
      }, // acceptance
      4: {
        label: getUserRole(currentRole),
        type: currentRole,
        icon: getIcon(currentRole),
      }, // approval
      5: { label: "Work Order Closed", type: "closed", icon: getIcon("closed") },
    }; // close
  }
  else if (classificationType == "CM-QR" && woClassification=="nonmedical"){
    
     userType = {
       1: {
         label: getUserRole(steps[1]?.role),
         type: steps[1]?.role,
         icon: getIcon(steps[1]?.role),
       },
       2: {
         label: getUserRole(steps[2]?.role),
         type: steps[2]?.role,
         icon: getIcon(steps[2]?.role),
       },
       3: {
         label: getUserRole(steps[3]?.role),
         type: steps[3]?.role,
         icon: getIcon(steps[3]?.role),
       },
       4: {
         label: getUserRole(steps[4]?.role),
         type: steps[4]?.role,
         icon: getIcon(steps[4]?.role),
       },
       5:{
         label: getUserRole(steps[5]?.role),
         type: steps[5]?.role,
         icon: getIcon(steps[5]?.role),

       },
       6: {
         label: getUserRole(steps[6]?.role),
         type: steps[6]?.role,
         icon: getIcon(steps[6]?.role),
       },
       7: {
         label: getUserRole(steps[7]?.role),
         type: steps[7]?.role,
         icon: getIcon(steps[7]?.role),
       },
       7: { label: "Work Order Closed", type: "closed", icon: getIcon("closed") }, // close

     };
    

  }
  if (siteModel === site_model.permanent && woClassification === classification.generic) {
    userType = {
      1: { label: "Help Desk", type: "helpdesk", icon: getIcon("helpdesk") }, // assigning
      2: userType[1], // assigning
      3: userType[2], // acceptance
      4: userType[2], // completion
      5: userType[1], // approval
      6: userType[5], // approval
      7: userType[7], // close
    };
  } else if (siteModel === site_model.makeShift) {
    userType = {
      1: userType[1],
      2: userType[1],
      3: userType[5],
      4: userType[7],
    };
  } else if (siteModel === site_model.imc) {
    userType = {
      1: userType[1],
      2: userType[2],
      3: userType[2],
      4: userType[6],
      5: userType[7],
    };
  } else if (siteModel === site_model.ascendServices) {
    userType = {
      1: { label: getUserRole("call_center"), type: "call_center", icon: getIcon("call_center") },
      2: { label: getUserRole(currentRole), type: currentRole, icon: getIcon(currentRole) },
      3: { label: getUserRole(currentRole), type: currentRole, icon: getIcon(currentRole) },
      4: { ...userType[6], label: getUserRole("requestor") },
    };
  }

  return userType[step];
};

export const getColor = (status) => {
  const woColor = {
    opened: "green",
    responded: "brown_dark",
    in_progress: "yellow",
    solved: "green_dark",
    supervisor_approved: "purple",
    qa_approved: "blue",
    hospitalHead_approved:"purple",
    approved: "initiated",
    closed: "black",
    reopened: "red_dark",
    rejected: "red",
    redirected: "red",
    assigned: "brown",
  };
  return woColor[status];
};
export const getColorstatic = (status) => {
  const woColor = {
    opened: "green",
    responded: "brown_dark",
    in_progress: "yellow",
    solved: "green_dark",
    closed: "purple",
    approved1: "blue",
    rejected: "black",
    approved: "red_dark",
    rejected: "red",
    redirected: "red",
    assigned: "brown",
    INITIATED: "brown_dark",
    accept: "yellow",
    ACCEPTED: "brown",
  };
  return woColor[status];
};


export const getStatus = (status,inProgressStatus, siteModel,classificationName) => {
  let woStatus = {
    opened: "Awaiting Maintenance",
    responded: "Responded",
    in_progress: "In Progress",
    solved: "Maintenance Completed",
    // supervisor_approved: "Supervisor Approved",
    // qa_approved: "QA Approved",
    hospitalHead_approved: "HOD Approved",
    supervisor_approved: "QA MOH Approval",
    qa_approved: "Requestor Approval",
    qa_approved: classificationName === "CM-QR" ? "QA MOH Approval" : "Requestor Approval",
    approved: "Head Approval",
    closed: "Closed",
    reopened: "Re Opened",
    rejected: "Rejected",
    redirected: "Redirected",
    assigned: "Assigned",
    awaiting_maintenance: "Awaiting Maintenance",
    maintenance_completed: "Maintenance Completed",
  };

  // if (siteModel && siteModel === site_model.permanent) {
  //   woStatus = {
  //     ...woStatus,
  //     supervisor_approved: "QA Approval",
  //     qa_approved: "Requestor Approval",
  //   };
  // } else
  if (inProgressStatus) {
    if (inProgressStatus === "awaiting_parts" || inProgressStatus === "awaiting_parts_by_3rd_party") {
      woStatus = {
        ...woStatus,
        in_progress: "In Progress (Awaiting Parts)",
      };
    }

  }
  if (siteModel && siteModel === site_model.makeShift) {
    woStatus = {
      ...woStatus,
      opened: "Awaiting Maintenance",
      solved: "Maintenance Completed",
    };
  } else if (siteModel && siteModel === site_model.imc) {
    woStatus = {
      ...woStatus,
      opened: "Awaiting Maintenance",
      solved: "Requestor Approval",
    };
  } else if (siteModel && siteModel === site_model.cmQR) {
    woStatus = {
      ...woStatus,
     qa_approved: "QA MOH Approval" 
    };
  }

  return woStatus[status];
};

// INVENTORY CURRENT STATUS
export const getICS = {
  NOT_WORKING: "Not Working",
  WORKING: "Working",
};

export const getACS = {
  ACCEPTED: "Accepted",
  SCRAPPING_INITIATED: "Scrapping Initiated",
  SCRAPPED: "Scrapped",
};

export const getIS = {
  IN_SERVICE: "In Service",
  RETIRED: "Retired",
};
export const getWarrantyStatus = {
  OUT_OF_WARRANTY: "Out of Warranty",
  UNDER_WARRANTY: "Under Warranty",
};

export const getRandomUniqueID = (length = 10) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const isSameModule = (pathname = null) => {
  let is = false;

  // ! Pervious and Current Path Params for module filtering reset purpose
  const previous = localStorage.getItem(PAGE.PREVIOUS);
  const current = localStorage.getItem(PAGE.CURRENT);

  const previousModule = previous?.split("/")?.[1];
  const currentModule = current?.split("/")?.[1];

  // ! added this extra condition if localstorage getItem might delay
  // ! and possibly we get false even if modules are same
  if (pathname) {
    const temp = pathname?.split("/")?.[1];

    if (temp === previousModule) {
      is = true;
    }
  } else if (previousModule === currentModule) {
    is = true;
  }
  return is;
};
export const getModuleName = (pathname = null) => {
  let path = "";
  if (typeof pathname === "string") {
    path = pathname?.split("/")?.[1];
  } else if (typeof pathname === "object") {
    path = pathname?.location?.pathname?.split("/")?.[1];
  }
  return path;
};