import { inventoryIcon, listIcon, scheduledIcon } from "assets/icons/svgIcons";

export default [
    {
        id: "Upload",
        title: "Upload Document",
        icon: inventoryIcon,
        pageTitle: "Upload Document",
        navLink: "/uploadDocument",
        type: "item",
        isActive: true,
        permissions: [
            "sheet_upload",

        ],
    },
];