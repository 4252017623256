import { TR } from "utility/transformers";
import InputMask from "react-input-mask";
import { observer } from "mobx-react-lite";
import { FormattedMessage } from "react-intl";
import { RiSearchLine } from "react-icons/ri";
import { BiLoaderCircle } from "react-icons/bi";
import { DatePicker } from "reactstrap-date-picker";
import { dateFormat } from "utility/helper/DateFormat";
import React, { Fragment, useContext, useState } from "react";
import { IntlContext } from "utility/context/Internationalization";
import { EnglishToArabicTransformer } from "utility/transformers/EngToArb";
import { FieldValidation } from "views/components/@custom/FieldValidation";
import { Col, Label, Input, FormGroup, InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";

import "./style.scss";

function CustomInput({
  sm,
  max,
  min,
  col,
  name,
  type,
  rows,
  icon,
  value,
  label,
  onClear,
  minDate,
  required,
  validation,
  isLoading,
  isDisabled,
  placeholder,
  isDetailView,
  handleChange,
  searchIcon,
  customColClasses,
  customClasses,
  customGroupClasses,
  removeSpaces,
  eventOnChangeHandler,
  additionalOnChangeHandler,
  isFilter,
  ...rest
}) {
  const context = useContext(IntlContext);
  const [isActive, setIsActive] = useState(false);
  let detailValue = "";
  const inputType = type || "text";
  const isDateType = inputType === "date";
  const isMaskType = inputType === "tel";
  const groupTagProps = {
    className: `${customGroupClasses} ${icon ? "input-group" : ""}`,
  };

  let inputGroupTagProps = {};
  let colTagProps = {
    className: `customInputContainer ${customColClasses}`,
  };

  if (col) {
    colTagProps = {
      ...colTagProps,
      xs: "12",
      sm: sm || "6",
    };
  }
  if (searchIcon || rest?.inputPrefix) {
    inputGroupTagProps = {
      ...inputGroupTagProps,
      className: `custom-inputGroupTag ${searchIcon ? "postFix" : "prefix"}`,
    };
  }

  // if (rest?.inputPrefix) {
  //   inputGroupTagProps = {
  //     ...inputGroupTagProps,
  //     className: "custom-inputGroupTag",
  //   };
  // }
  let incomingValue = `${value}`;
  if (incomingValue !== undefined) {
    incomingValue = incomingValue.replace(/"/g, "'");
  }

  let customProps = {
    name,
    value: incomingValue,
    disabled: isDisabled,
    className: `customInput form-control ${customClasses} h-44 ${icon ? "iconInput" : ""} ${isActive ? "active" : ""}`,
    placeholder: placeholder || "Enter",
    ...rest,
  };
  if (rows) {
    customProps = {
      ...customProps,
      rows,
    };
  }
  if (isDateType) {
  
    customProps = {
      ...customProps,
      value: (typeof value === 'string' || value instanceof String) ? value : (value ? value.toISOString().split('T')[0] : value),
      minDate: minDate ? minDate?.toISOString() : "",
      className: `${customProps?.className} customDatePicker h-44`,
      onClear: onClear,
    };
  }

  const ColTag = col ? Col : "div";
  const GroupTag = label ? FormGroup : "div";
  const InputTag = isDateType ? DatePicker : isMaskType ? InputMask : Input;
  const InputGroupTag = searchIcon || rest?.inputPrefix ? InputGroup : Fragment;
  const validationObj = validation ? validation?.(name) : {};

  const getTargetValue = (e) =>
    isDateType
      ? e
        ? new Date(e)
        : e
      : type === "email" || removeSpaces
        ? TR.removeSpaces(e.target.value)
        : e?.target?.value;

  // ONLY FOR MASK INPUT
  if (isMaskType) {
    customProps = {
      ...customProps,
      mask: "+\\966 99 999 9999",
    };
  }

  const localOnChange = (e) => {
    if (handleChange) {
      let changedValue = getTargetValue(e);

      if (inputType === "number") {
        if (changedValue > max) {
          changedValue = customProps.value;
        }
      }

      handleChange({
        key: name,
        value: changedValue,
      });
    }
    if (additionalOnChangeHandler) {
      additionalOnChangeHandler(e?.target?.value || e);
    }
    if (eventOnChangeHandler) {
      eventOnChangeHandler(e);
    }
  };
  const toggleFocus = () => {
    setIsActive(!isActive);
    customProps = {
      ...customProps,
      className: `${customProps.className} ${isActive ? "active" : ""}`,
    };
  };
  if (customProps.value === "undefined" || customProps.value === undefined) {
    customProps.value = "";
  }

  // IF DETAIL VIEW GET DETAIL VALUE
  if (isDetailView) {
    detailValue = customProps.value || "N/A";

    if (isDateType) {
      detailValue = detailValue && detailValue !== "N/A" ? dateFormat(detailValue) : detailValue;
    }

    colTagProps = {
      ...colTagProps,
      className: `${colTagProps.className} detailView`,
    };
  }

  if (max) {
    customProps = {
      ...customProps,
      max: max,
    };
  }
  if (min !== undefined && min !== "") {
    customProps = {
      ...customProps,
      min: min,
    };
  }

  // REMOVING UNWANTED PROPS FROM CUSTOM_PROPS
  delete customProps?.inputPrefix;

  return (
    <ColTag {...colTagProps}>
      <GroupTag {...groupTagProps}>
        {label && (
          <Label className={`${required && !isDetailView ? "required" : ""}`}>
            {<FormattedMessage id={label} defaultMessage={label} />}
          </Label>
        )}
        {!isDetailView ? (
          <>
            <InputGroupTag {...inputGroupTagProps}>
              {rest?.inputPrefix && (
                <span className={`prefixElement ${isDisabled ? "disabled" : ""}`}>{rest.inputPrefix}</span>
              )}
              <InputTag
                dir="auto"
                type={inputType}
                {...customProps}
                placeholder={EnglishToArabicTransformer(customProps.placeholder, context?.state?.direction)}
                onChange={localOnChange}
                onFocus={toggleFocus}
                onBlur={toggleFocus}
                calendarContainer={isFilter === "filter" ? document.body : ""}
              />

              {searchIcon && <SearchIconAddon isLoading={isLoading} isActive={isActive} />}
            </InputGroupTag>

            {validationObj?.isValidationError && <FieldValidation validations={validationObj?.error} isTransparent />}
          </>
        ) : (
          <p>{detailValue}</p>
        )}
      </GroupTag>
    </ColTag>
  );
}

export default observer(CustomInput);

export function SearchIconAddon(props) {
  const Icon = props?.isLoading ? BiLoaderCircle : RiSearchLine;
  let iconProps = {
    color: "#959db2",
    size: 25,
  };

  if (props?.isLoading) {
    iconProps = {
      ...iconProps,
      className: "reloading",
    };
  }

  return (
    <InputGroupAddon addonType="append">
      <InputGroupText className={`${props?.isActive ? "active" : ""}`}>
        <Icon {...iconProps} />
      </InputGroupText>
    </InputGroupAddon>
  );
}
