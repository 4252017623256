import { SubDepartmentIcon } from "assets/icons";

export default [
  {
    id: "subDepartments",
    title: "Sub Departments",
    icon: SubDepartmentIcon,
    pageTitle: "Sub Departments",
    navLink: "/subDepartments",
    type: "item",
    permissions: [
      "super_admin",
      "site_admin",
      "organization_admin",
      "engineer",
      "hmc_admin",
      "hmc_supervisor",
      "hmc_technician",
      "qa",
      "helpdesk",
    ],
  },
];